



















































































































  import { Validator } from '@/constant/Mixins';
  import { Toaster } from '@/constant/Toaster';
  import { ServerResponse } from '@/services/response.types';
  import { AddressBookModel, UserModel } from '@/store/auth/types';
  import { CityModel, ProvinceModel } from '@/store/location/types';
  import { BSelectOption } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Mixins, Model, Prop } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'addressBook',
    components: {},
    mixins: [],
  })
  export default class addressBook extends Mixins(Validator) {
    @Action('GetProvince', { namespace: 'location' }) GetProvince!: (params?: any) => Promise<ServerResponse>;
    @Action('GetCity', { namespace: 'location' }) GetCity!: (params?: any) => Promise<ServerResponse>;
    @Action('GetAddresBook', { namespace: 'auth' }) GetAddresBook!: (params?: any) => Promise<ServerResponse>;
    @Action('AddAddresBook', { namespace: 'auth' }) AddAddresBook!: (params?: any) => Promise<ServerResponse>;
    @Action('UpdateAddresBook', { namespace: 'auth' }) UpdateAddresBook!: (params?: any) => Promise<ServerResponse>;
    @Action('DeleteAddresBook', { namespace: 'auth' }) DeleteAddresBook!: (params?: any) => Promise<ServerResponse>;

    @State('province', { namespace: 'location' }) province!: ProvinceModel[];
    @State('city', { namespace: 'location' }) city!: CityModel[];
    @State('user', { namespace: 'auth' }) user!: UserModel;
    @State('address', { namespace: 'auth' }) addressList!: AddressBookModel[];

    @Prop({type: Boolean}) selector !: boolean

    public form = {
      address_id: null,
      label: '',
      reciever: '',
      phone: '',
      address: '',
      city_id: 0,
      state_id: 0,
      zipcode: '',
    };
    public showModal = false;
    public onEdit = false;

    public get optionProvince(): BSelectOption[] {
      return this.province?.map((item) => {
        return {
          text: item.state_name,
          value: item.state_id,
        };
      });
    }


    public address : AddressBookModel[] = []


    public get optionCity(): BSelectOption[] {
      return this.city?.map((item) => {
        return {
          text: item.city_name,
          value: item.city_id,
        };
      });
    }

    public created(): void {
      this.GetProvince({ page: 1 });
      this.GetData()
    }


   /**
    * GetData
    */
   public GetData() {
     this.GetAddresBook().then(()=>this.appendAddressAttrs())
   }

   /**
    * appendAddressAttrs
    */
   public appendAddressAttrs() {
     this.address = this.addressList.map((item) => {
        item.province_name = this.GetProvinceNameByID(item.state_id)
        item.city_name = this.GetCityNameByStateID(item.state_id, item.city_id)
        return item
      })
   }

    public mounted(): void {
      this.form.phone = this.user.phone??""
      this.form.reciever = `${this.user.first_name} ${this.user.last_name}`
    }

    /**
     * OnDelete
     */
    public OnDelete(address: AddressBookModel) {
      this.$bvModal
        .msgBoxConfirm('apakah anda yakin?', {
          title: 'Konfirmasi menghapus',
          centered: true,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          cancelVariant: 'success',
          titleTag: 'h6',
          headerBgVariant: 'warning',
          headerClass: 'p-2 text-white',
          footerClass: 'd-flex justify-content-between border-0 p-2',
          bodyClass: 'text-center bold pb-0',
        })
        .then((ok) => {
          if (ok) {
            let form = new FormData
            form.append("address_id", address.address_id)
            this.DeleteAddresBook(form).then((res) => {
              Toaster.Make('deleted!', { variant: 'success' });
              this.GetData()
            })
          }
        });
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      let form = new FormData(ev.target as HTMLFormElement);

      if(this.form.address_id){
        this.UpdateAddresBook(form).then((res) => {
          this.showModal = false;
          this.form.address_id = null
          this.GetData()
        })
      }else{
        this.AddAddresBook(form).then((res) => {
          this.showModal = false;
          this.GetData()
        });
      }
    }

    /**
     * GetProvinceNameByID
     */
    public GetProvinceNameByID(state_id): string {
      return this.province?.filter((item)=>String(item.state_id) === String(state_id))[0]?.state_name??""
    }

    /**
     * GetCityNameByCityId
     */
    public GetCityNameByCityId(city_id): string {
      return this.city?.filter((item)=>String(item.city_id) === String(city_id))[0]?.city_name??""
    }

    /**
     * GetCityNameByStateID
     */
    public GetCityNameByStateID(state_id, city_id): string {
      let name = this.GetCityNameByCityId(city_id)

      if(name.length === 0){
        this.GetCity({state_id}).then((res)=>{
          name = this.GetCityNameByCityId(city_id)
        })
      }
      return name
    }

    /**
     * OnClickEdit
     */
    public OnClickEdit(address: AddressBookModel) {
      this.form.label = address.title_address
      this.form.reciever = address.reciever
      this.form.phone = address.phone
      this.form.address = address.address
      this.form.city_id = Number(address.city_id)
      this.form.state_id = Number(address.state_id)
      this.form.zipcode = address.zipcode
      this.form.address_id = address.address_id

      this.showModal = true
    }

    /**
     * OnSelect
     */
    public OnSelect(address: AddressBookModel) {
      this.$emit("onSelected", address)
    }
  }
